import axios from "axios";
import {
  PostFeedbackRequest,
  SendVerificationCodeRequest,
  VerifyCodeRequest,
  UpdateEmailRequest,
  CreateCandidateRefiLoanRequest,
  UpdateCandidateRefiLoanRequest,
} from "@/models/api";

class BaseApiService {
  // Since .NET is handling the routing, we don't need to include baseUrl
  // All API calls in controllers will start with {api}
  baseUrl = "api";
  resource: string;

  constructor(resource: string) {
    if (!resource) throw new Error("Resource is not provided");
    this.resource = resource;
  }

  getUrl(id = "") {
    return `${window.location.origin}/${this.baseUrl}/${this.resource}/${id}`;
  }

  handleErrors(err: any): Promise<any> {
    // Note: here you may want to add your errors handling
    console.log("ERROR", { err });
    // add error:true at the end in case message is used elsewhere
    if (err.response?.data && typeof err.response.data === "object") {
      return { ...err.response.data, error: true };
    } else {
      return { ...err, error: true };
    }
  }

  async get() {
    try {
      const response = await axios(this.getUrl());
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}

class LookupsApiService extends BaseApiService {
  constructor() {
    super("lookups");
  }

  async getSchoolInfoLookups(programTypeId?: number) {
    try {
      const response = await axios(this.getUrl(`school-info?programTypeId=${programTypeId}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getRefiSchoolInfoLookups(programTypeId?: number, degreeLevelTypeId?: number) {
    try {
      const url = this.getUrl(`school-info`);

      const params = degreeLevelTypeId
        ? {
            programTypeId,
            degreeLevelId: degreeLevelTypeId,
          }
        : {
            programTypeId,
          };

      const response = await axios.get(url, {
        params: params,
      });
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
  async getPersonalInfoLookups() {
    try {
      const response = await axios(this.getUrl("personal-info"));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getRegistrationLookups() {
    try {
      const response = await axios(this.getUrl("registration"));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getAdditionalInfoLookups(applicantType: string, productTypeId: number) {
    try {
      const response = await axios(this.getUrl(`additional-info/${applicantType}/${productTypeId}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getLoanConfigLookups() {
    try {
      const response = await axios(this.getUrl("loan-config"));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getApprovalLookUps() {
    try {
      const response = await axios(this.getUrl("approval"));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getEnrollmentTermsLookups() {
    try {
      const response = await axios(this.getUrl(`school-confirmation`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getAppLenderConfiguration(lid: number) {
    try {
      const response = await axios(this.getUrl(`lender?${lid}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getConsentTypes() {
    try {
      const response = await axios(this.getUrl(`consent-types`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getSurveyQuestionTypes() {
    try {
      const response = await axios(this.getUrl(`survey-question-types`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}

class LeadService extends BaseApiService {
  constructor() {
    super("lead");
  }

  async processLead(body: any) {
    try {
      const response = await axios.post(this.getUrl("process"), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}

class ApplicationService extends BaseApiService {
  constructor() {
    super("application");
  }

  async getApplication(appId: number) {
    try {
      const response = await axios(this.getUrl(), {
        params: { appId },
      });
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async updateApplication(body: any) {
    try {
      const response = await axios.put(this.getUrl(), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getApplicationInviteForType(appId: number, applicantType?: number) {
    try {
      const response = await axios(this.getUrl(`${appId}/invites`), {
        params: { applicantType },
      });
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getApplicationInvites(appId: number) {
    try {
      const response = await axios(this.getUrl(`${appId}/invites`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async addOrUpdateInitialDraw(appId: number, body: any) {
    try {
      const response = await axios.put(this.getUrl(`${appId}/initialdraw`), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getInitialDraw(appId: number) {
    try {
      const response = await axios(this.getUrl(`${appId}/initialdraw`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async addOrUpdateAppLoanConfig(body: any) {
    try {
      const response = await axios.post(this.getUrl("LoanConfigs"), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getAppLoanConfig(appId: number) {
    try {
      const response = await axios(this.getUrl(`${appId}/LoanConfigs`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async addOrUpdateRatePlanOverride(body: any) {
    try {
      const response = await axios.post(this.getUrl("RatePlanOverrides"), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getApplicationRatePlanOverride(appId: number, ratePlanId: number) {
    try {
      const response = await axios(this.getUrl(`${appId}/RatePlans/${ratePlanId}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getApplicationRatePlanByRatePlanId(appId: number, ratePlanId: number) {
    try {
      const response = await axios(this.getUrl(`${appId}/RatePlans/${ratePlanId}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getApplicationRatePlansByApplicationId(appId: number, lid: number) {
    try {
      const response = await axios(this.getUrl(`${appId}/RatePlans?LID=${lid}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getMockApplicationRatePlansByApplicationId(appId: number, lid: number) {
    try {
      const response = await axios(this.getUrl(`mock?LID=${lid}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
  async getApplicationDecision(appId: number) {
    try {
      const response = await axios(this.getUrl(`decision/${appId}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}

class ConfigurationService extends BaseApiService {
  constructor() {
    super("configuration");
  }

  async getLenderConfiguration(lid: number, programTypeId: number, productTypeId: number) {
    try {
      const response = await axios(
        this.getUrl(`lender/${lid}/programType/${programTypeId}/productType/${productTypeId}`),
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getRates(lid: number, programTypeId: number, productTypeId: number) {
    try {
      const response = await axios(
        this.getUrl(`rates/${lid}/programType/${programTypeId}/productType/${productTypeId}`),
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getLenderConfigurationByLenderId(lid: number) {
    try {
      const response = await axios(this.getUrl(`lender/${lid}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}

class DocumentsService extends BaseApiService {
  constructor() {
    super("documents");
  }

  async getApplicationDocumentViewLinks(applicationId: number) {
    try {
      const response = await axios.get(this.getUrl(`application/${applicationId}/view-links`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getApplicationDocumentViewLinksByDocumentType(applicationId: number, documentTypeId: number) {
    try {
      const response = await axios.get(
        this.getUrl(`application/${applicationId}/documentType/${documentTypeId}/view-links`),
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}

class PersonService extends BaseApiService {
  constructor() {
    super("person");
  }

  async getUnifiUser(personId: number, appId: number) {
    try {
      const response = await axios(this.getUrl("unifi"), {
        params: { personId, appId },
      });
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async checkUsername(body: any) {
    try {
      const response = await axios.post(this.getUrl(`check`), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async registerUser(body: any) {
    try {
      const response = await axios.post(this.getUrl("register"), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async submitApplication(personId: number) {
    try {
      const response = await axios.post(this.getUrl(`applicant/submit/${personId}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async addApplicant(applicationId: number, applicantTypeId: number) {
    try {
      const response = await axios.post(this.getUrl("applicant/add"), {
        applicationId,
        applicantTypeId,
      });

      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getApplicant(personId: number) {
    try {
      const response = await axios(this.getUrl(`applicant/${personId}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async updateCitizenship(personId: number, body: any) {
    try {
      const response = await axios.put(this.getUrl(`applicant/${personId}/citizenship`), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async addAnnualIncome(body: any) {
    try {
      const response = await axios.post(this.getUrl(`applicant/salary`), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async updateAnnualIncome(body: any) {
    try {
      const response = await axios.put(this.getUrl(`applicant/salary`), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async addIncome(body: any) {
    try {
      const response = await axios.post(this.getUrl(`applicant/Income`), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async updateIncome(personId: number, incomeId: number, body: any) {
    try {
      const response = await axios.put(this.getUrl(`applicant/${personId}/Income/${incomeId}`), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async addAddress(body: any) {
    try {
      const response = await axios.post(this.getUrl(`applicant/Address`), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async updateAddress(personId: number, addressId: number, body: any) {
    try {
      const response = await axios.put(this.getUrl(`applicant/${personId}/Address/${addressId}`), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async addSurveyResponse(body: any) {
    try {
      const response = await axios.post(this.getUrl("applicant/surveyresponses"), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async acceptInvite(token: string) {
    try {
      const response = await axios.post(this.getUrl(`applicant/Invites/${token}/accept`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async inviteApplicant(body: any) {
    try {
      const response = await axios.post(this.getUrl("applicant/invites"), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async addOrUpdateHousingOption(body: any) {
    try {
      const response = await axios.post(this.getUrl("applicant/housing"), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getHousing(personId: number) {
    try {
      const response = await axios(this.getUrl(`applicant/${personId}/housing`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async addOrUpdateLiability(body: any) {
    try {
      const response = await axios.post(this.getUrl("applicant/liabilities"), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getApplicantLiabilities(personId: number) {
    try {
      const response = await axios(this.getUrl(`applicant/${personId}/liabilities`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async verifyApplicantNationalIdNumberRequest(body: any) {
    try {
      const response = await axios.post(this.getUrl("verify"), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async sendVerificationCodeByEmail(body: SendVerificationCodeRequest) {
    try {
      const response = await axios.post(this.getUrl("sendVerificationCodeByEmail"), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async verifyCode(body: VerifyCodeRequest) {
    try {
      const response = await axios.post(this.getUrl("verifyCode"), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async updateEmailAddress(body: UpdateEmailRequest) {
    try {
      const response = await axios.post(this.getUrl("updateEmail"), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async calculateApplicantAge(body: any) {
    try {
      const response = await axios.post(this.getUrl("calculateAge"), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}

class SchoolService extends BaseApiService {
  constructor() {
    super("schools");
  }

  async getSchools() {
    try {
      const response = await axios(this.getUrl(""));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getSchoolsByProgram(programTypeId: number, productTypeId: number, lenderId: number) {
    try {
      const url = this.getUrl(`program/${encodeURIComponent(programTypeId)}`);
      const response = await axios.get(url, {
        params: {
          productTypeId: productTypeId,
          lenderId: lenderId,
        },
      });

      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getSchool(schoolId: number) {
    try {
      const response = await axios(this.getUrl(`${schoolId}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}

class InvitesService extends BaseApiService {
  constructor() {
    super("invites");
  }
  async getInvite(token: string) {
    try {
      const response = await axios(this.getUrl(`${token}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}

class ConsentsService extends BaseApiService {
  constructor() {
    super("consents");
  }
  async addConsent(body: any) {
    try {
      const response = await axios.post(this.getUrl(""), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getAllConsents(applicantId: number) {
    try {
      const response = await axios(this.getUrl(`applicants/${applicantId}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
  async getRegistrationConsents() {
    try {
      const response = await axios(this.getUrl("registration"));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getCreditPullConsent() {
    try {
      const response = await axios(this.getUrl("credit"));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}

class ReturnService extends BaseApiService {
  constructor() {
    super("return");
  }

  async acceptReturnLink(token: string): Promise<any> {
    try {
      const response = await axios.post(this.getUrl(`accept?token=${token}`));

      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}

class FeedbackService extends BaseApiService {
  constructor() {
    super("feedback");
  }

  async sendFeedback(body: PostFeedbackRequest) {
    try {
      const response = await axios.post(this.getUrl(""), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getFeedbackFormOptions(formId: number) {
    try {
      const response = await axios.get(this.getUrl(`${formId}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}

class RefiService extends BaseApiService {
  constructor() {
    super("refi");
  }

  async getDegreeLevels() {
    try {
      const response = await axios.get(this.getUrl(`DegreeLevels`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getProgramsByDegreeLevelTypeId(lenderId: number, productTypeId: number, degreeLevelTypeId: number) {
    try {
      const response = await axios.get(
        this.getUrl(`ProgramPlans/lender/${lenderId}/product/${productTypeId}/degreeLevel/${degreeLevelTypeId}`),
      );
      return response;
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getServicers() {
    try {
      const response = await axios.get(this.getUrl(`mockServicerData`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getCandidateRefiLoans(applicationId: number) {
    try {
      const response = await axios.get(this.getUrl(`${applicationId}/CandidateRefiLoans`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async addCandidateRefiLoan(body: CreateCandidateRefiLoanRequest) {
    try {
      const response = await axios.post(this.getUrl(`CandidateRefiLoan`), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async deleteCandidateRefiLoan(applicationId: number, candidateRefiLoanId: number) {
    try {
      const response = await axios.delete(this.getUrl(`${applicationId}/candidateRefiLoans/${candidateRefiLoanId}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async updateCandidateRefiLoan(body: UpdateCandidateRefiLoanRequest) {
    try {
      const response = await axios.patch(this.getUrl(`CandidateRefiLoan`), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async updateCandidateRefiLoans(body: UpdateCandidateRefiLoanRequest[]) {
    try {
      const response = await axios.patch(this.getUrl(`CandidateRefiLoans`), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async calculatePayments(applicantId: number, body: any) {
    try {
      const response = await axios.post(this.getUrl(`applicants/${applicantId}/CalculatePayments`), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async softPullSubmit(applicantId: number) {
    try {
      const response = await axios.post(this.getUrl(`applicant/${applicantId}/SoftPull`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getRefiRatePlans(applicationId: number) {
    try {
      const response = await axios.get(this.getUrl(`application/${applicationId}/RatePlans`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getSoftPullRatePlans(applicantId: number) {
    try {
      const response = await axios.get(this.getUrl(`applicants/${applicantId}/SoftPullRatePlans`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}

export const $api = {
  lookups: new LookupsApiService(),
  leads: new LeadService(),
  applications: new ApplicationService(),
  configuration: new ConfigurationService(),
  documents: new DocumentsService(),
  person: new PersonService(),
  school: new SchoolService(),
  consents: new ConsentsService(),
  invites: new InvitesService(),
  returns: new ReturnService(),
  feedback: new FeedbackService(),
  refi: new RefiService(),
};
